import { Link, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as styles from './in-the-media.module.scss';
import { Wrap } from '../../sections-wrap';

function Media() {
  const data = useStaticQuery(graphql`
    {
      pagesSettings: allContentfulPageMediaConfig {
        nodes {
          id
          mediaPageTitle
          featuredMediaArticles {
            id
            title
            uri
            description {
              description
            }
            detailImage {
              image {
                gatsbyImage(width: 400, placeholder: NONE)
              }
              altText
            }
          }
        }
      }
    }
  `);
  const { pagesSettings } = data;
  const mediaPageSettings = pagesSettings.nodes.find(
    (page) => page.id === process.env.GATSBY_MEDIA_PAGES_SETTINGS_ID,
  );
  const pageTitle = mediaPageSettings?.mediaPageTitle;
  const articles = mediaPageSettings?.featuredMediaArticles?.slice(0, 2);
  return (
    <div className={styles.sectionContainer}>
      <Wrap>
        <div className={styles.mediaContainer}>
          <span className={styles.title}>{pageTitle}</span>
          <div className={styles.mediaArticlesContainer}>
            {articles?.map((article) => (
              <Link className={styles.articleContainer} to={`/media${article?.uri}`}>
                {article?.detailImage
              && (
              <GatsbyImage
                imgClassName={styles.imageWidth}
                className={styles.imageWidth}
                loading="eager"
                image={getImage(article?.detailImage.image)}
                alt={article?.detailImage.altText}
              />
              )}
                <span className={styles.subtitle} to={`/media${article?.uri}`}>Learn more</span>
              </Link>
            ))}
          </div>
        </div>
      </Wrap>
    </div>
  );
}

export { Media };
