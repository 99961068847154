import { graphql, useStaticQuery } from 'gatsby';
import * as styles from './why.module.scss';
import { Wrap } from '../sections-wrap';

function Why() {
  let data = useStaticQuery(graphql`
    {
      wpPage(slug: {eq: "home"}) {
        homePageConfiguration {
          eaHomeWhyTitle
          eaHomeWhySubtitle
          eaHomeWhyReasons {
            bigText
            littleText
          }
        }
      }
    }
  `);
  data = data?.wpPage?.homePageConfiguration;
  return (
    <div className={styles.whyContainer}>
      <Wrap>
        <div className={styles.header}>
          <h2>{data?.eaHomeWhyTitle}</h2>
          <h3
            dangerouslySetInnerHTML={{ __html: data?.eaHomeWhySubtitle }}
          />
        </div>
        <div className={styles.content}>
          <div className={styles.reasons}>
            {data?.eaHomeWhyReasons?.map((item) => (
              <div className={styles.reason} key={item.bigText}>
                <div className={styles.bigText}>{item.bigText}</div>
                <div className={styles.littleText}>{item.littleText}</div>
              </div>
            ))}
          </div>
        </div>
      </Wrap>
    </div>
  );
}

export { Why };
