import { Link } from 'gatsby';
import { object } from 'prop-types';
import { capitalizeEveryWord } from '../../../helpers/misc'
import * as styles from './attorney-main-image-sign.module.scss';

function AttorneyMainImageSign({ data }) {
  const {
    eaHomePageHeaderPersonClickthroughUrl: uri,
    eaHomePageHeaderPersonTitle: title, eaHomePageHeaderPersonSubtitle: subtitle,
  } = data;

  let shortSubtitle = '';
  const permanence = subtitle?.toLowerCase().includes('partner') ? 'partner' : 'associate';
  const subtitleSpplited = subtitle?.toLowerCase().split(permanence);
  if (subtitleSpplited?.length > 1) {
    [shortSubtitle] = subtitleSpplited;
  }

  return (
    <div className={styles.imageTextContainer}>
      <Link to={uri}>
        <div className={styles.name}>{title}</div>
        {subtitleSpplited?.length > 1 ? (
          <div className={styles.partnerContainer}>
            <span className={styles.partner}>{permanence.toUpperCase()}</span>
            <div className={styles.partner}>
              {capitalizeEveryWord(shortSubtitle)}
            </div>
          </div>
        ) : (
          <div className={styles.partner}>
            {subtitle}
          </div>
        )}
      </Link>
    </div>
  );
}

AttorneyMainImageSign.propTypes = {
  data: object,
};

AttorneyMainImageSign.defaultProps = {
  data: {},
};

export { AttorneyMainImageSign };
