import { graphql, useStaticQuery, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Action } from './actions';
import { AttorneyMainImageSign } from './attorney-sign/attorney-main-image-sign';
import * as styles from './main-image.module.scss';

function MainImage() {
  const data = useStaticQuery(graphql`
  {
    wpPage(slug: {eq: "home"}) {
      homePageConfiguration {
        eaHomePageHeaderPersonClickthroughUrl
        eaHomePageHeaderPersonTitle
        eaHomePageHeaderPersonSubtitle
        eaHomePageHeaderPerson {
          gatsbyImage(width:500, placeholder: NONE)
        }
        eaHomePageHeaderBackground {
          gatsbyImage(width:1080, layout: FULL_WIDTH)
        }
      }
    }
  }
`);

  const imagesData = data.wpPage.homePageConfiguration;
  const backimg = getImage(imagesData?.eaHomePageHeaderBackground?.gatsbyImage);
  const personImage = getImage(imagesData?.eaHomePageHeaderPerson?.gatsbyImage);

  return (
    <div className={styles.mainImageContainer}>
      <GatsbyImage
        image={backimg}
        loading="eager"
        alt="Main image Background"
        className={styles.backgroundImage}
      />
      <div className={styles.wrap}>
        <div className={styles.content}>
          <div className={styles.contentContainer}>
            <h2 className={styles.title}>
              More Than
              <br />
              Just Lawyers
            </h2>
            <hr className={styles.line} />
            <Action />
            <Link to="/locations/" className={styles.assessment}>
              Schedule your case assessment now!
            </Link>
          </div>
          <div className={styles.personContainer}>
            <Link
              to={data.wpPage?.homePageConfiguration.eaHomePageHeaderPersonClickthroughUrl}
              className={styles.person}
            >
              <GatsbyImage
                image={personImage}
                loading="eager"
                className={styles.imagePerson}
                objectFit="fill"
                alt="hero"
              />
              <AttorneyMainImageSign data={imagesData} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export { MainImage };
