import { string } from 'prop-types';
import { Link } from 'gatsby';
import call from '../../../../../assets/icons/call.svg';

import * as styles from './icons.module.scss';

function Call({ className }) {
  return (
    <Link className={styles.action} to="/locations/">
      <div className={className}>
        <img src={call} alt="call icon" />
      </div>
      <div className={styles.content}>
        Call
      </div>
    </Link>

  );
}

Call.propTypes = {
  className: string,
};

Call.defaultProps = {
  className: '',
};

export { Call };
