import { graphql } from 'gatsby';
import { MainImage } from '../components/home-main-image';
import { Media } from '../components/media/in-the-media';
import { Why } from '../components/home-why';
import { BrowseByPA } from '../components/browse-by-pa';
import { BrowseHome } from '../components/browse';
import { InTouch } from '../components/home-in-touch';
import { MainImageMobile } from '../components/home-main-image/main-image-mobile';
import * as styles from './index.module.scss';

function IndexPage() {
  return (
    <main>
      <div className={styles.desktop}>
        <MainImage />
      </div>
      <div className={styles.mobile}>
        <MainImageMobile />
      </div>
      <Media />
      <Why />
      <BrowseByPA />
      <BrowseHome />
      <InTouch />
    </main>
  );
}

export const query = graphql`
  {
    wpPage(slug: {eq: "home"}) {
      title
      homePageConfiguration {
        eaHomeBrowseTitle
      }
      ...SeoPage
    }
  }
`;

export default IndexPage;

export { Head } from '../components/seo/seo';
