import { string } from 'prop-types';
import chat from '../../../../../assets/icons/chat.svg';
import { ChatButton } from '../../../../common/chat-button';

import * as styles from './icons.module.scss';

function Chat({ className }) {
  return (
    <div className={styles.action}>
      <ChatButton className={styles.content}>
        <div className={className}>
          <img src={chat} alt="chat icon" />
        </div>
        Chat
      </ChatButton>
    </div>

  );
}

Chat.propTypes = {
  className: string,
};

Chat.defaultProps = {
  className: '',
};

export { Chat };
