// extracted by mini-css-extract-plugin
export var block = "in-touch-module--block--87c75";
export var blocks = "in-touch-module--blocks--1a977";
export var content = "in-touch-module--content--78bf6";
export var emailContainer = "in-touch-module--email-container--a4e8b";
export var header = "in-touch-module--header--0ad40";
export var inTouchContainer = "in-touch-module--in-touch-container--4ef67";
export var phoneContainer = "in-touch-module--phone-container--8e38a";
export var socialContainer = "in-touch-module--social-container--e2f38";
export var socials = "in-touch-module--socials--486d4";
export var title = "in-touch-module--title--eea9d";