// extracted by mini-css-extract-plugin
export var backgroundImage = "main-image-mobile-module--backgroundImage--58260";
export var button = "main-image-mobile-module--button--37a34";
export var buttonContainer = "main-image-mobile-module--buttonContainer--90f60";
export var containerAbsoluteInsideImage = "main-image-mobile-module--container-absolute-inside-image--d2683";
export var contentContainer = "main-image-mobile-module--content-container--a15ed";
export var halfLine = "main-image-mobile-module--half-line--4023f";
export var imageContainer = "main-image-mobile-module--imageContainer--4f6d0";
export var mainImageContainer = "main-image-mobile-module--mainImageContainer--bd08d";
export var mobileContainer = "main-image-mobile-module--mobileContainer--120ce";
export var subtitle = "main-image-mobile-module--subtitle--b6d07";
export var title = "main-image-mobile-module--title--b668c";