import * as styles from './action.module.scss';
import { Call, Chat, Schedule } from '../icons-components';

function Action() {
  return (
    <div className={styles.actions}>
      <Call className={styles.icon} />
      <Chat className={styles.icon} />
      <Schedule className={styles.icon} />
    </div>

  );
}
export { Action };
