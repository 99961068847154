// extracted by mini-css-extract-plugin
export var assessment = "main-image-module--assessment--204fe";
export var backgroundImage = "main-image-module--backgroundImage--6e9c1";
export var content = "main-image-module--content--30881";
export var contentContainer = "main-image-module--content-container--e14e3";
export var imagePerson = "main-image-module--image-person--7745a";
export var line = "main-image-module--line--08ab4";
export var mainImageContainer = "main-image-module--mainImageContainer--945a6";
export var person = "main-image-module--person--fe365";
export var personContainer = "main-image-module--person-container--d5652";
export var title = "main-image-module--title--db13a";
export var wrap = "main-image-module--wrap--08f5b";