import { string } from 'prop-types';
import { useState } from 'react';
import * as styles from './icons.module.scss';
import schedule from '../../../../../assets/icons/schedule-home.svg';
import { ZoomCalendar } from '../../../../zoom-calendar';

function Schedule({ className }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      {open && (
        <ZoomCalendar onClose={() => setOpen(false)} />
      )}
      <button
        type="button"
        className={styles.scheduleAction}
        onClick={() => setOpen(true)}
      >
        <div className={className}>
          <img src={schedule} alt="schedule icon" />
        </div>
        <div className={styles.content}>
          Schedule
        </div>
      </button>
    </>
  );
}

Schedule.propTypes = {
  className: string,
};

Schedule.defaultProps = {
  className: '',
};

export { Schedule };
