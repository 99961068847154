import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as styles from './main-image-mobile.module.scss';
import { Action } from './actions';
import { AttorneyMainImageSign } from './attorney-sign/attorney-main-image-sign';

function MainImageMobile() {
  const data = useStaticQuery(graphql`
  {
    wpPage(slug: {eq: "home"}) {
      homePageConfiguration {
        eaHomePageHeaderPersonClickthroughUrl
        eaHomePageHeaderPersonTitle
        eaHomePageHeaderPersonSubtitle
        eaHomePageHeaderPersonMobile {
          gatsbyImage(width: 260, height: 360, placeholder: NONE)
        }
        eaHomePageHeaderBackground {
          gatsbyImage(width:1080, layout: FULL_WIDTH)
        }
      }
    }
  }
`);

  const imagesData = data.wpPage.homePageConfiguration;
  const backimg = getImage(imagesData?.eaHomePageHeaderBackground?.gatsbyImage);
  const personImage = getImage(imagesData?.eaHomePageHeaderPersonMobile?.gatsbyImage);
  return (
    <div className={styles.mainImageContainer}>
      <GatsbyImage
        image={backimg}
        loading="eager"
        alt="Main image Background"
        className={styles.backgroundImage}
      />
      <div className={styles.containerAbsoluteInsideImage}>
        <div className={styles.mobileContainer}>
          <div className={styles.contentContainer}>
            <div className={styles.title}>
              More Than
              <br />
              Just Lawyers
            </div>
            <hr className={styles.halfLine} />
            <Action />
            <div className={styles.subtitle}>
              Schedule your case assessment now!
            </div>
          </div>
          <div className={styles.imageContainer}>
            <Link
              to={imagesData.eaHomePageHeaderPersonClickthroughUrl}
            >
              <GatsbyImage
                loading="eager"
                alt={imagesData.eaHomePageHeaderPersonTitle}
                image={personImage}
                objectFit="cover"
              />
            </Link>
            <AttorneyMainImageSign data={imagesData} />
          </div>
        </div>
      </div>
    </div>
  );
}

export { MainImageMobile };
