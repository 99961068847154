import { Link } from 'gatsby';
import * as styles from './in-touch.module.scss';
import { Wrap } from '../sections-wrap';
import email from '../../assets/icons/email.svg';
import facebook from '../../assets/icons/facebook.svg';
import instagram from '../../assets/icons/instagram.svg';
import linkedin from '../../assets/icons/linkedin.svg';
import talk from '../../assets/icons/talk.svg';
import twitter from '../../assets/icons/twitter.svg';
import vimeo from '../../assets/icons/vimeo.svg';
import youtube from '../../assets/icons/youtube.svg';

function InTouch() {
  return (
    <div className={styles.inTouchContainer}>
      <Wrap>
        <div className={styles.header}>
          <h2>Get in Touch</h2>
        </div>
        <div className={styles.content}>
          <div className={styles.blocks}>
            <div className={styles.block}>
              <a href="tel:(303) 688-0944" className={styles.phoneContainer}>
                <img src={talk} alt="talk icon" />
                <span>Talk to a Lawyer</span>
                {' '}
                <br />
                <span>(303) 688-0944</span>
              </a>
              {' '}
            </div>
            {' '}
            <div className={styles.block}>
              <Link to="/free-consultation/" className={styles.emailContainer}>
                <img src={email} alt="email icon" />
                <span>Email Us</span>
                <br />
                <span>RobinsonAndHenry.com</span>
              </Link>
            </div>
            <div className={styles.block}>
              <div className={styles.socialContainer}>
                <a href="/" className={styles.title}>Follow Us</a>
                <p className={styles.socials}>
                  <a href="https://www.linkedin.com/company/robinson-&amp;-henry-p-c-" target="_blank" rel="noopener noreferrer">
                    <img src={linkedin} alt="linkedin icon" />
                  </a>
                  <a href="https://twitter.com/robinsonhenry" target="_blank" rel="noopener noreferrer">
                    <img src={twitter} alt="twitter icon" />
                  </a>
                  <a href="https://www.facebook.com/robinsonandhenry/" target="_blank" rel="noopener noreferrer">
                    <img src={facebook} alt="facebook icon" />
                  </a>
                  <a href="https://www.instagram.com/robinsonandhenrypc/" target="_blank" rel="noopener noreferrer">
                    <img src={instagram} alt="instagram icon" />
                  </a>
                  <a href="https://www.youtube.com/channel/UC8pXatFvdTq2fWs_2cyWgug" target="_blank" rel="noopener noreferrer">
                    <img src={youtube} alt="youtube icon" />
                  </a>
                  <a href="https://vimeo.com/user109864896" target="_blank" rel="noopener noreferrer">
                    <img src={vimeo} alt="vimeo icon" />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Wrap>
    </div>
  );
}

export { InTouch };
